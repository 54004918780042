<template>
  <v-app>
    <h1 class="ma-2 ml-0">Clientes</h1>
    <v-card v-if="customers.length">
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <v-row>
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            dense
            hide-details
            class="user-search col-sm-6 pa-2"
            v-debounce="applyFilter"
            debounce-events="input"
          >
          </v-text-field>
          <v-select
            v-model="filterSelect"
            :items="filterSelectItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            class="col-sm-2 pa-2"
            @change="applyFilter"
          ></v-select>
        </v-row>

        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="my-2 text-capitalize"
            :block="isMobile"
            @click="openCustomerForm"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Cliente</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="25"
        :page.sync="page"
        :headers="tableColumns"
        :items="customersShow"
        :loading="loading"
        loading-t4rext="Carregando Tabela..."
        @page-count="pageCount = $event"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }"
      >
        <template v-slot:item.name="{ item }">
          <v-flex class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span v-else class="font-weight-medium">{{
                avatarText(item.name)
              }}</span>
            </v-avatar>

            <v-flex class="d-flex flex-column ms-3">
              <span>
                {{ item.name }}
              </span>
              <small>"{{ item.alias }}"</small>
            </v-flex>
          </v-flex>
        </template>

        <template v-slot:item.is_pj="{ item }">
          <div v-if="item.is_pj">
            <v-col>
              <small>CNPJ</small><br />
              <strong>{{ item.cnpj }}</strong>
            </v-col>
          </div>

          <div v-if="item.is_pf">
            <v-col>
              <small>CPF</small><br />
              <strong>{{ item.cpf }}</strong>
            </v-col>
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openCustomerForm(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status == 1"
                @click="openAlertConfirmation(item)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Inativar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-else @click="openAlertConfirmation(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Reativar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:footer class="d-flex">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </template>

        <template v-slot:no-data>
          <nothing-component></nothing-component>
        </template>
      </v-data-table>
    </v-card>

    <nothing-component v-if="!customers.length && !loading">
      <div :class="{ 'w-full py-2': isMobile }">
        <v-btn
          color="success"
          class="my-2 text-capitalize"
          :block="isMobile"
          @click="openCustomerForm"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Novo Cliente</span>
        </v-btn>
      </div>
    </nothing-component>

    <v-flex v-if="loading" justify-center>
      <v-progress-circular indeterminate></v-progress-circular>
    </v-flex>
    <CustomerForm ref="customerForm" @onSubmit="getCustomers" />

    <Alert
      ref="alert"
      @inactive="deleteCustomer($event)"
      @active="restoreCustomer($event)"
    />
  </v-app>
</template>

<script>
import CustomerForm from "@/components/customers/form/CustomerForm";
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiRestore,
} from "@mdi/js";

import { getDirective } from "vue-debounce";

import NothingComponent from "@/components/nothingComponent.vue";

import Alert from "@/components/app/alert/Alert";

import tableMixin from "@/util/mixins/tableMixin";

export default {
  name: "Customers",

  components: { CustomerForm, NothingComponent, Alert },

  mixins: [tableMixin],

  directives: {
    debounce: getDirective(),
  },

  data() {
    return {
      searchQuery: null,

      customers: [],
      customersFiltered: [],
      filterSelect: "name",
      loading: false,
      page: null,
      pageCount: null,

      tableColumns: [
        { text: "NOME", value: "name" },
        { text: "CADASTRO", value: "is_pj" },
        { text: "TELEFONE", value: "phone" },
        { text: "E-MAIL", value: "email" },
        { text: "STATUS", value: "status" },
        { text: "AÇÕES", value: "actions" },
      ],

      filterSelectItems: [
        { text: "Nome", value: "name" },
        { text: "CPF", value: "cpf" },
        { text: "CNPJ", value: "cnpj" },
        { text: "Telefone", value: "phone" },
        { text: "E-Mail", value: "email" },
      ],

      icons: {
        mdiPlus,
        mdiExportVariant,
        mdiDeleteOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiRestore,
      },
    };
  },

  computed: {
    customersShow() {
      return this.searchQuery ? this.customersFiltered : this.customers;
    },
  },

  mounted() {
    this.getCustomers();
  },

  methods: {
    getCustomers() {
      this.loading = true;

      this.$http
        .index("/customer")
        .then((res) => {
          this.customers = res.data.data;

          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    openCustomerForm(content) {
      this.$refs.customerForm.open(content.id);
    },

    openAlertConfirmation(item) {
      this.$refs.alert.open(item);
    },

    deleteCustomer(content) {
      this.$http
        .destroy("/customer/" + content.id)
        .then((res) => {
          this.getCustomers();
        })
        .catch((err) => console.log(err));
    },

    applyFilter() {
      if (this.searchQuery) {
        this.$http
        .index("/customer", {
          fieldTable: this.filterSelect,
          q: this.searchQuery,
        })
        .then((response) => {
          this.customersFiltered = response.data.data;
        });
      }
    },
  },
};
</script>