var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Clientes")]),(_vm.customers.length)?_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('v-row',[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.applyFilter),expression:"applyFilter"}],staticClass:"user-search col-sm-6 pa-2",attrs:{"placeholder":"Pesquisar","outlined":"","dense":"","hide-details":"","debounce-events":"input"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-select',{staticClass:"col-sm-2 pa-2",attrs:{"items":_vm.filterSelectItems,"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":""},on:{"change":_vm.applyFilter},model:{value:(_vm.filterSelect),callback:function ($$v) {_vm.filterSelect=$$v},expression:"filterSelect"}})],1),_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":_vm.openCustomerForm}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Novo Cliente")])],1)],1)],1),_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.page,"headers":_vm.tableColumns,"items":_vm.customersShow,"loading":_vm.loading,"loading-t4rext":"Carregando Tabela...","footer-props":{
        itemsPerPageOptions: [25, 50, 100, -1],
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageAllText: 'Todos',
        disablePagination: true,
        nextIcon: '',
        prevIcon: '',
        pageText: '',
      }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('v-flex',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])],1),_c('v-flex',{staticClass:"d-flex flex-column ms-3"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v("\""+_vm._s(item.alias)+"\"")])])],1)]}},{key:"item.is_pj",fn:function(ref){
      var item = ref.item;
return [(item.is_pj)?_c('div',[_c('v-col',[_c('small',[_vm._v("CNPJ")]),_c('br'),_c('strong',[_vm._v(_vm._s(item.cnpj))])])],1):_vm._e(),(item.is_pf)?_c('div',[_c('v-col',[_c('small',[_vm._v("CPF")]),_c('br'),_c('strong',[_vm._v(_vm._s(item.cpf))])])],1):_vm._e()]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[(item.status === 1)?_c('div',[_vm._v("Ativo")]):_vm._e(),(item.status === 0)?_c('div',[_vm._v("Inativo")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openCustomerForm(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),(item.status == 1)?_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmation(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Inativar")])],1)],1):_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmation(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRestore)+" ")]),_c('span',[_vm._v("Reativar")])],1)],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('nothing-component')]},proxy:true}],null,false,1913018118)})],1):_vm._e(),(!_vm.customers.length && !_vm.loading)?_c('nothing-component',[_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":_vm.openCustomerForm}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Novo Cliente")])],1)],1)]):_vm._e(),(_vm.loading)?_c('v-flex',{attrs:{"justify-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('CustomerForm',{ref:"customerForm",on:{"onSubmit":_vm.getCustomers}}),_c('Alert',{ref:"alert",on:{"inactive":function($event){return _vm.deleteCustomer($event)},"active":function($event){return _vm.restoreCustomer($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }