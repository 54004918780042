<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      origin="center center"
      persistent
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title>
          {{ isUpdating ? "Editar" : "Cadastrar" }} Cliente
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title>
          <v-icon class="mr-1">
            {{ icons.mdiAccount }}
          </v-icon>
          <small>Dados de Cliente</small>
        </v-card-title>

        <v-flex v-if="loading" justify-center>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="secondary"
            class="ma-2"
          />
        </v-flex>

        <v-form
          v-show="!loading"
          v-model="formValid"
          ref="form"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="customerForm.name"
                  :rules="[rules.required]"
                  label="Nome"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="customerForm.alias"
                  label="Apelido"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="modelTypeSelected"
                  :items="typeSelect"
                  item-text="text"
                  item-value="value"
                  :rules="[rules.required]"
                  label="Tipo de Pessoa"
                  outlined
                  dense
                  hide-details="auto"
                  :change="typePersonSelected"
                ></v-select>
              </v-col>

              <v-col
                v-show="customerForm.is_pf == 1 || modelTypeSelected == 1"
                cols="6"
              >
                <v-text-field
                  v-model="customerForm.cpf"
                  :rules="[rules.required, rules.cpf]"
                  v-mask="'###.###.###-##'"
                  label=" CPF "
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                v-show="customerForm.is_pj == 1 || modelTypeSelected == 2"
                cols="6"
              >
                <v-text-field
                  v-model="customerForm.cnpj"
                  :rules="[rules.required, rules.cnpj]"
                  v-mask="'##.###.###/####-##'"
                  label="CNPJ"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-show="customerForm.is_foreigner == 1 || modelTypeSelected == 3"
                cols="6"
              >
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-show="modelTypeSelected == 2"
                  v-model="customerForm.inscricao_municipal"
                  label="Inscrição Municipal"
                  type="number"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-checkbox
                  v-show="modelTypeSelected == 2"
                  v-model="customerForm.inscricao_estadual_isento"
                  label="Isento de Inscrição Estadual"
                  hide-details="auto"
                  dense
                ></v-checkbox>
              </v-col>

              <v-col>
                <v-text-field
                  v-show="modelTypeSelected == 2"
                  v-model="customerForm.inscricao_estadual"
                  label="Inscrição Estadual"
                  type="number"
                  outlined
                  dense
                  hide-details="auto"
                  v-if="customerForm.inscricao_estadual_isento != 1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="customerForm.whatsapp"
                  v-mask="'(##) # ####-####'"
                  outlined
                  label="WhatsApp"
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="customerForm.phone"
                  v-mask="'(##) # ####-####'"
                  label="Telefone"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="customerForm.email"
                  label="E-Mail"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-divider></v-divider>

        <v-card-title>
          <v-icon class="mr-1">
            {{ icons.mdiMapMarker }}
          </v-icon>
          <small>Endereço</small>
        </v-card-title>
        <v-flex v-if="loading" justify-center>
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </v-flex>
        <v-card-text>
          <address-component
            v-if="!loading"
            ref="address"
            :initialData="customerForm.main_address"
            :dialog="dialog"
            @submit="handleAddress"
            :dense="true"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" class="text-capitalize" @click="close">
            Cancelar
          </v-btn>
          <v-btn class="text-capitalize" color="success" @click="submitForm">
            <v-icon>{{ icons.mdiContentSave }}</v-icon>
            Salvar Informações
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mdiAccount, mdiMapMarker, mdiContentSave } from "@mdi/js";
import AddressComponent from "@/components/app/address/addressComponent.vue";
import { TYPE_PERSON } from "@/constants/option";
import formMixin from "@/util/mixins/formMixin";

export default {
  components: { AddressComponent },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      isUpdating: false,
      loading: false,

      formValid: null,
      typeSelect: TYPE_PERSON,
      modelTypeSelected: null,

      customerForm: {
        id: null,
        tenant_id: 1,
        name: null,
        avatar: null,
        alias: null,
        status: null,
        is_pj: null,
        is_pf: null,
        is_foreigner: null,
        cnpj: null,
        cpf: null,
        inscricao_estadual: null,
        inscricao_estadual_isento: null,
        inscricao_municipal: null,
        whatsapp: null,
        phone: null,
        email: null,
        main_address: {
          type: "mainAddress",
          cep: null,
          number: null,
          street: null,
          neighborhood: null,
          complement: null,
          state: null,
          city: null,
        },
      },

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
        email: (v) =>
          (v ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) : null) ||
          "Insira um e-mail valido.",
        phoneNumber: (v) =>
          (v ? /(\(?\d{2}\)?\s)?(\d{4,5}-?\d{4})/.test(v) : null) ||
          "Insira um numero de telefone valido.",
        cnpj: (v) =>
          /^\d{2}\.\d{3}\.\d{3}\/\d{4}-?\d{2}$/.test(v) ||
          "Insira um CNPJ valido",
        cpf: (v) =>
          /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/.test(v) || "Insira um CPF valido",
      },

      icons: {
        mdiAccount,
        mdiContentSave,
        mdiMapMarker,
      },
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },

    typePersonSelected: function () {
      if (this.customerForm.is_pf == 1) {
        return (this.modelTypeSelected = this.typeSelect[0].value);
      }
      if (this.customerForm.is_pj == 1) {
        return (this.modelTypeSelected = this.typeSelect[1].value);
      }
    },
  },

  methods: {
    open(id = null) {
      if (id) {
        this.getCustomer(id);
        this.isUpdating = true;

        this.dialog = true;
      }
      this.customerForm.is_pf = null;
      this.customerForm.is_pj = null;
      this.customerForm.whatsapp = null;
      this.customerForm.phone = null;

      this.dialog = true;
    },

    getCustomer(id) {
      this.loading = true;

      this.$http
        .index(`/customer/${id}`)
        .then((res) => {
          this.customerForm = res.data;

          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    setTypePerson() {
      if (this.modelTypeSelected == 1) {
        this.customerForm.is_pf = 1;
        this.customerForm.is_pj = 0;
        this.customerForm.is_foreigner = 0;
      }
      if (this.modelTypeSelected == 2) {
        this.customerForm.is_pj = 1;
        this.customerForm.is_pf = 0;
        this.customerForm.is_foreigner = 0;
      }
      if (this.modelTypeSelected == 3) {
        this.customerForm.is_pj = 0;
        this.customerForm.is_pf = 0;
        this.customerForm.is_foreigner = 1;
      }
    },

    handleAddress(e) {
      this.customerForm.main_address = e;
      this.customerForm.main_address.type = "mainAddress";
    },

    close() {
      this.customerForm.cnpj = null;
      this.customerForm.cpf = null;
      this.customerForm.inscricao_estadual = null;
      this.customerForm.id = null

      this.isUpdating = false

      this.$refs.form.reset();
      this.$refs.address.resetForm();

      this.dialog = false;
    },

    submitForm() {
      if (this.isUpdating == true) {
        this.updateForm();
        this.close();
      } else {
        this.storeForm();
        this.close();
      }
    },

    storeForm() {
      this.setTypePerson();

      this.$http
        .store("/customer", this.customerForm)
        .then((res) => {
          this.dialog = false;

          this.$emit("onSubmit");
        })
        .catch((err) => console.log(err));
    },

    updateForm() {
      this.setTypePerson();
      this.$http
        .$put(`/customer/${this.customerForm.id}`, this.customerForm)
        .then((res) => {
          this.dialog = false;

          this.$emit("onSubmit");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>